@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Raleway:wght@300;400;900&display=swap');

*{
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  /* background-color: rgb(35, 34, 34); */
}

a{
  text-decoration: none;
  color: gold;

}